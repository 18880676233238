document.addEventListener("DOMContentLoaded", function () {
    const toggleContainer = document.getElementById("toggler");
    const toggledContainer = document.getElementById("toggledContainer");
    const rotateImage = document.getElementById("rotate");

    toggleContainer.addEventListener("click", function () {
        if (toggledContainer.style.maxHeight === "0px" || toggledContainer.style.maxHeight === "") {
            toggledContainer.style.maxHeight = toggledContainer.scrollHeight + "px";
            toggledContainer.style.opacity = 1;
            rotateImage.style.transform = "rotate(0deg)";
        } else {
            toggledContainer.style.maxHeight = "0";
            toggledContainer.style.opacity = 0;
            rotateImage.style.transform = "rotate(180deg)";
        }
    });
});

document.addEventListener("DOMContentLoaded", function () {
    function scrollToAnchor(anchor) {
        const target = document.querySelector(anchor);
        if (target) {
            const offsetPercentage = 0.25; // 20% der Viewport-Höhe früher stoppen
            const targetPosition = target.getBoundingClientRect().top + window.pageYOffset - (window.innerHeight * offsetPercentage);
            window.scrollTo({
                top: targetPosition,
                behavior: "smooth"
            });
        }
    }

    const links = document.querySelectorAll("li a");

    links.forEach(function (link) {
        link.addEventListener("click", function (event) {
            event.preventDefault();
            const anchor = this.getAttribute("href");
            scrollToAnchor(anchor);
        });
    });
});

document.addEventListener("DOMContentLoaded", function () {
    const playButton = document.getElementById("playbutton");
    const muteButton = document.getElementById("mute");
    const video = document.getElementById("teaser");
    const playingImg = document.getElementById("playing");
    const pauseImg = document.getElementById("pause");
    const unmutedImg = document.getElementById("unmuted");
    const mutedImg = document.getElementById("muted");

    function muteVideo() {
        video.muted = true;
        unmutedImg.style.display = "none";
        mutedImg.style.display = "block";
    }


    playButton.addEventListener("click", function () {
        if (video.paused) {
            video.play();
            playButton.style.display = "none";
        } else {
            video.pause();
        }
    });

    muteButton.addEventListener("click", function () {
        video.muted = !video.muted;
    });

    video.addEventListener("volumechange", function () {
        if (video.muted) {
            unmutedImg.style.display = "none";
            mutedImg.style.display = "block";
        } else {
            unmutedImg.style.display = "block";
            mutedImg.style.display = "none";
        }
    });



    if (video.paused) {
        playingImg.style.display = "block";
        pauseImg.style.display = "none";
    } else {
        playingImg.style.display = "none";
        pauseImg.style.display = "block";
    }
});
